import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { Stack, Typography } from '@mui/material';
import { JobListResponse } from 'hooks/types/jobs';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { classes } from '../styles';
import { StyledStack } from './styles';

type JobListLayoutProps = {
  jobListData: JobListResponse;
};

export function JobListLayout(props: JobListLayoutProps) {
  const { jobListData } = props;
  const { t } = useTranslation();
  const locations = t('myGRIMME_group_otherlocations', 'weitere Standorte');

  return (
    <StyledStack mb={5} spacing={2}>
      {jobListData.jobs.map((job) => {
        const locationDetails =
          (job.locations[0].zipCode === null ? '' : job.locations[0].zipCode) +
          ' ' +
          job.locations[0].city +
          ', ' +
          job.locations[0].country +
          (job.locations.length > 1
            ? ' ' + `( +${job.locations.length} ${locations})`
            : ' ');

        return (
          <Link
            className={classes.card}
            href={`/career/jobs/${job.id}`}
            key={job.id}
          >
            <Stack direction={'row'}>
              <Stack spacing={1}>
                <Typography
                  color={theme.palette.primary.main}
                  variant="overline"
                >
                  {job.functions.map((fun) => fun.name)}
                </Typography>
                <Typography variant="body1">{job.title}</Typography>
                <Stack
                  className={classes.caption}
                  direction={'row'}
                  spacing={1}
                >
                  <Typography className={classes.colorGrey} variant="caption">
                    {job.categories.map((category, index) => (
                      <React.Fragment key={category.id}>
                        {category.name}
                        {index !== job.categories.length - 1 && ', '}
                      </React.Fragment>
                    ))}
                  </Typography>
                  <Typography className={classes.colorGrey} variant="caption">
                    &bull;
                  </Typography>
                  <Typography className={classes.colorGrey} variant="caption">
                    {job.company.name}
                  </Typography>
                  <Typography className={classes.colorGrey} variant="caption">
                    &bull;
                  </Typography>
                  <Typography className={classes.colorGrey} variant="caption">
                    {locationDetails}
                  </Typography>
                </Stack>
              </Stack>
              <FontAwesomeIcon className={classes.icon} icon={faChevronRight} />
            </Stack>
          </Link>
        );
      })}
    </StyledStack>
  );
}
