import { SpacedSection } from '@grimme/components';
import theme from '@grimme/theme';
import { Box, Container, Grid } from '@mui/material';
import { SectionTitle } from '../section-title';
import { BenefitItem } from './benefit-item';

export type ButterBenefits = {
  benefit_item: {
    companies: string;
    icon: string;
    icon_type: string;
    subtitle: string;
    title: string;
  }[];
  icon_color?: string;
  overline?: string;
  title?: string;
};

type BenefitsProps = {
  butterBenefits: {
    benefit_item: {
      companies: string;
      icon: string;
      icon_type: string;
      subtitle: string;
      title: string;
    }[];
    icon_color?: string;
    overline?: string;
    title?: string;
  };
  companyId: string;
};

export function Benefits(props: BenefitsProps) {
  const { butterBenefits, companyId } = props;
  return (
    <Box bgcolor={theme.palette.common.white}>
      <SpacedSection px="large">
        <Container>
          <SectionTitle
            overline={butterBenefits.overline}
            showDivider={false}
            title={butterBenefits.title}
          />
          <Grid
            container
            columns={{
              lg: 12,
              md: 8,
              sm: 4,
              xs: 4,
            }}
            pt={3}
            spacing={5}
          >
            {butterBenefits.benefit_item
              .filter((benefit) => benefit.companies.includes(companyId))
              .map((benefit, index) => (
                <BenefitItem
                  iconColor={butterBenefits.icon_color}
                  item={benefit}
                  key={index}
                />
              ))}
          </Grid>
        </Container>
      </SpacedSection>
    </Box>
  );
}
