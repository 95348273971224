import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { GrimmeGroupCareerJobPage } from 'lib/butter-types/pages/grimme-group-career-job-page';
import { HtmlTag } from './consts';
import { StyledStack, classes } from './styles';

export async function fetchVideoData(videoUrl: string) {
  const baseUrl = 'https://youtube.com/oembed?';

  const data = await fetch(
    baseUrl +
      new URLSearchParams({
        format: 'json',
        url: videoUrl,
      }),
  ).then((response) => response.json());

  return {
    thumbnailUrl: data.thumbnail_url,
    title: data.title,
    youtubeUrl: videoUrl,
  };
}

export function getCorrectImage(
  inputId: string,
  hero_image_variants: GrimmeGroupCareerJobPage['hero_image_variants'],
) {
  const result = hero_image_variants.filter(
    (item) => item.job_category === inputId,
  );
  if (result.length > 0) {
    return result[0].image;
  } else {
    return null;
  }
}

export function getCorrectVideoUrl(
  inputId: string,
  companyVideoVariants: GrimmeGroupCareerJobPage['company_video_variants'],
): string {
  const videoVariant = companyVideoVariants.find(
    (variant) => variant.company === inputId,
  );
  return videoVariant
    ? videoVariant.url
    : 'https://www.youtube.com/watch?v=8Ve56YR7ERQ';
}

export const formatHtml = (
  htmlString1: string,
  htmlString2: string,
): JSX.Element[] => {
  const parser = new DOMParser();
  const concatenatedhtmlString = htmlString1.concat(htmlString2);
  const htmlDoc = parser.parseFromString(concatenatedhtmlString, 'text/html');
  const h2Elements = htmlDoc.getElementsByTagName('h2');
  const liElements = htmlDoc.getElementsByTagName('li');
  const pElements = htmlDoc.getElementsByTagName('p');

  for (let i = 0; i < pElements.length; i++) {
    const p = pElements[i];
    const innerText = p.innerText;
    while (p.firstChild) {
      p.removeChild(p.firstChild);
    }
    p.outerHTML = `<p>${innerText}</p>`;
  }

  for (let i = 0; i < h2Elements.length; i++) {
    const h2 = h2Elements[i];
    const innerText = h2.innerText;
    while (h2.firstChild) {
      h2.removeChild(h2.firstChild);
    }
    h2.outerHTML = `<h2>${innerText}</h2>`;
  }

  for (let i = 0; i < liElements.length; i++) {
    const li = liElements[i];
    const innerText = li.innerText;
    while (li.firstChild) {
      li.removeChild(li.firstChild);
    }
    li.outerHTML = `<li>${innerText}</li>`;
  }

  const formattedHtml = htmlDoc.body.innerHTML;

  const extractTagsAndValues = (htmlString: string) => {
    const tagObjects = [];
    const tagRegex = /<([^>]+)>([^<]*)<\/\1>/g;
    const removeRegex = /&[^;]+;/g;
    let match;

    const trimmedString = htmlString.replace(removeRegex, '');

    while ((match = tagRegex.exec(trimmedString)) !== null) {
      const tagName = match[1];
      const tagValue = match[2].trim();
      tagObjects.push({ tagName, tagValue });
    }

    return tagObjects;
  };

  const extractedTagsAndValues = extractTagsAndValues(formattedHtml);

  const mappedData = extractedTagsAndValues.map((item, index) => {
    switch (item.tagName) {
      case HtmlTag.P:
        return (
          <Typography key={index} mb={2} mt={2} variant="body1">
            {item.tagValue}
          </Typography>
        );
      case HtmlTag.H2:
        return (
          <Typography key={index} mb={3} mt={3} variant="h4">
            {item.tagValue}
          </Typography>
        );
      case HtmlTag.Li:
        return (
          <StyledStack direction={'row'} key={index} mb={1}>
            <FontAwesomeIcon className={classes.icon} icon={faChevronRight} />
            <Typography>{item.tagValue}</Typography>
          </StyledStack>
        );
      default:
        return null;
    }
  });

  return mappedData;
};
