import { Select } from '@grimme/components';
import { Box, Container, Stack, Typography } from '@mui/material';
import { JobResources } from 'hooks/types/jobs';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useTranslation } from 'next-i18next';
import { Ref, useMemo } from 'react';
import { translateLocationId } from '../jobs-list/chips/utils';
import { Search } from './search';
import { StyledBox, classes } from './styles';
import { JobFilterKeys } from './types';

type JobsFilterProps = {
  resources: JobResources;
  scrollRef: Ref<HTMLDivElement>;
};

export function JobsFilter(props: JobsFilterProps) {
  const { resources, scrollRef } = props;
  const { t } = useTranslation();

  const title = t(
    'myGRIMME_group_joblist_title',
    'Stellenangebote durchsuchen',
  );
  const functions = t('myGRIMME_group_functions', 'Funktionsbereich');
  const location = t('myGRIMME_group_location', 'Standort');
  const categories = t('myGRIMME_group_categories', 'Karrierelevel');
  const company = t('myGRIMME_group_company', 'Unternehmen');
  const { getQueryParamAsArray, handleRemoveQuery, setParams } =
    useRouterQueryFilters();

  const locationOptions = useMemo(() => {
    if (resources.locations) {
      return resources.locations.map((location) => {
        const label =
          (location.zipCode || '') +
          ' ' +
          location.city +
          translateLocationId(location.id);
        return {
          label: label,
          value: location.id,
        };
      });
    }
    return [];
  }, [resources.locations]);

  const companyOption = useMemo(() => {
    if (resources.companies) {
      return resources.companies.map((companies) => ({
        label: companies.name,
        value: companies.id,
      }));
    }
    return [];
  }, [resources.companies]);

  const functionsOptions = useMemo(() => {
    if (resources.functions) {
      return resources.functions.map((functions) => ({
        label: functions.name,
        value: functions.id,
      }));
    }
    return [];
  }, [resources.functions]);

  const categoriesOptions = useMemo(() => {
    if (resources.categories) {
      return resources.categories.map((categories) => ({
        label: categories.name,
        value: categories.id,
      }));
    }
    return [];
  }, [resources.categories]);

  const functionsQuery = getQueryParamAsArray(JobFilterKeys.FUNCTIONS);
  const companyQuery = getQueryParamAsArray(JobFilterKeys.COMPANIES);
  const categoriesQuery = getQueryParamAsArray(JobFilterKeys.CATEGORIES);
  const locactionsQuery = getQueryParamAsArray(JobFilterKeys.LOCATIONS);

  return (
    <StyledBox ref={scrollRef}>
      <Container>
        <Stack>
          <Typography mb={3} mt={5} variant="h3">
            {title}
          </Typography>
          <Box className={classes.filterGrid}>
            <Search />
            <Select
              fullWidth
              multiple
              label={functions}
              name="functions"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value.length === 0
                  ? handleRemoveQuery('functions')
                  : setParams({
                      functions: e.target.value,
                      page: 1,
                    });
              }}
              options={functionsOptions}
              value={functionsQuery || ''}
            />
            <Select
              fullWidth
              multiple
              label={categories}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value.length === 0
                  ? handleRemoveQuery('categories')
                  : setParams({ categories: e.target.value, page: 1 });
              }}
              options={categoriesOptions}
              value={categoriesQuery || ''}
            />
            <Select
              fullWidth
              multiple
              label={company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value.length === 0
                  ? handleRemoveQuery('companies')
                  : setParams({ companies: e.target.value, page: 1 });
              }}
              options={companyOption}
              value={companyQuery || ''}
            />
            <Select
              fullWidth
              multiple
              label={location}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value.length === 0
                  ? handleRemoveQuery('locations')
                  : setParams({ locations: e.target.value, page: 1 });
              }}
              options={locationOptions}
              value={locactionsQuery || ''}
            />
          </Box>
        </Stack>
      </Container>
    </StyledBox>
  );
}
