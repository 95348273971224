import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('CareerPageJobsFilter', [
  'filterGrid',
  'icon',
]);

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.ourShadows.overlay.lightBG,
  paddingBottom: theme.spacing(5),
  [`& .${classes.filterGrid}`]: {
    display: 'grid',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gap: theme.spacing(3),
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  [`& .${classes.icon}`]: {
    height: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    maxHeight: theme.spacing(1.5),
    maxWidth: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
}));
