import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('PaginationBar', [
  'paginationComponents',
]);

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    padding: theme.spacing(5, 0),
  },

  [`& .${classes.paginationComponents}`]: {
    display: 'flex',
    flex: 0.75,
    justifyContent: 'center',
  },
}));
