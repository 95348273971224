import { useRouter } from 'next/router';
import { useGeolocation } from '../useGeolocation';
import { useProfile } from '../useProfile';

export const useUserCountryCode = () => {
  const { locale } = useRouter();
  const { data: profile } = useProfile();
  const { data: geolocationData } = useGeolocation(locale);

  const countryCodeFromProfile = profile?.company?.address?.countryCode;
  const countryCodeFromGeolocation = geolocationData?.countryCode;
  if (countryCodeFromProfile || countryCodeFromGeolocation) {
    return countryCodeFromProfile || countryCodeFromGeolocation;
  } else {
    return undefined;
  }
};
