import { Grid, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('BenefitItem', [
  'fontAwesomeIcon',
  'gridContainer',
  'iconBox',
  'stackInner',
  'stackWrapper',
  'typography',
]);

export const Root = styled(Grid)(({ theme }) => ({
  width: '100%',
  [`& .${classes.fontAwesomeIcon}`]: {
    height: '24px',
    textAlign: 'center',
    verticalAlign: 'center',
    width: '24px',
  },
  [`& .${classes.iconBox}`]: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    inset: 'auto 0 0 auto',
    justifyContent: 'center',
    maxHeight: '56px',
    maxWidth: '56px',
    minHeight: '56px',
    minWidth: '56px',
  },
  [`& .${classes.stackInner}`]: {
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginTop: 0,
    width: 'unset',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  [`& .${classes.stackWrapper}`]: {
    flexDirection: 'row',
    justifyContent: 'start',
  },
  [`& .${classes.typography}`]: {
    color: theme.palette.grey[300],
  },
}));
