import theme from '@grimme/theme';
import { Box, Pagination, useMediaQuery } from '@mui/material';
import { Root, classes } from './styles';

export type PaginationBarProps = {
  onPageChange?: (page: number) => void;
  page: number;
  totalPages: number;
};

export const PaginationBar = (props: PaginationBarProps) => {
  const { onPageChange, page, totalPages } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Root>
      <Box className={classes.paginationComponents}>
        <Pagination
          boundaryCount={1}
          color="primary"
          count={totalPages}
          onChange={(_, page) => {
            onPageChange(page);
          }}
          page={page}
          siblingCount={1}
          size={isMobile ? 'small' : 'medium'}
        />
      </Box>
    </Root>
  );
};
