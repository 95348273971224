import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TitleDivider } from '@grimme/components';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { Job } from 'hooks/types/jobs';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Benefits, ButterBenefits } from '../benefits/benefits';
import { Contacts } from '../contacts';
import { JobFilterKeys } from '../jobs-filter/types';
import { translateLocationId } from '../jobs-list/chips/utils';
import { YoutubeTeaser } from '../youtube-teaser';
import { JobInfoStack } from './job-info-stack';
import { Root, classes } from './styles';
import { formatHtml } from './utils';

type JobLayoutProps = {
  butterBenefits: ButterBenefits;
  contacts: { subtitle: string; title: string };
  jobData: Job;
  videoData: {
    thumbnailUrl: string;
    title: string;
    youtubeUrl: string;
  };
};

export function JobLayout(props: JobLayoutProps) {
  const { butterBenefits, contacts, jobData, videoData } = props;
  const { t } = useTranslation();
  const { getQueryParam } = useRouterQueryFilters();
  const router = useRouter();
  const [formattedHtml, setFormattedHtml] = useState<JSX.Element[]>(null);

  useEffect(() => {
    const formattedHtml = formatHtml(jobData.description, jobData.footer);
    setFormattedHtml(formattedHtml);
  }, [jobData]);

  const back = t('myGRIMME_group_backtooverview', 'Zurück zur Übersicht');
  const youtubeButtonLabel = t('myGRIMME_group_applynow', 'Jetzt bewerben');

  const fields = jobData && [
    {
      data: jobData.categories.map((cat) => cat.name),
      label: t('myGRIMME_group_categories', 'Karrierelevel'),
    },
    {
      data: jobData.functions.map((func) => func.name),
      label: t('myGRIMME_group_functions', 'Funktionsbereich'),
    },
    {
      data: jobData.locations
        .map(
          (loc) =>
            `${loc.zipCode || ''} ${loc.city} ${translateLocationId(loc.id)}`,
        )
        .join(' | '),
      label: t('myGRIMME_group_location', 'Standort'),
    },
    {
      data: jobData.company.name,
      label: t('myGRIMME_group_company', 'Unternehmen'),
    },
  ];

  const hideBenefits =
    getQueryParam(JobFilterKeys.LOCATIONS) === ('POL' || 'NLD');

  const handleNavigation = () => {
    router.back();
  };
  return (
    <>
      <Root>
        {jobData && (
          <Container>
            <Button
              onClick={handleNavigation}
              startIcon={
                <FontAwesomeIcon className="mb-[3px]" icon={faAngleLeft} />
              }
              variant="text"
            >
              {back}
            </Button>
            <Typography mb={3} mt={5} variant="h2">
              {jobData.title}
            </Typography>
            <Stack
              columnGap={3}
              direction="row"
              flexWrap={'wrap'}
              mb={3}
              rowGap={1}
            >
              {fields.map((field, index) => (
                <JobInfoStack key={index} {...field} />
              ))}
            </Stack>
            <TitleDivider className={classes.titleDivider} />
            <Grid container columnSpacing={20} rowSpacing={5}>
              <Grid item md={8}>
                {formattedHtml}
              </Grid>
              <Grid item md={4}>
                <YoutubeTeaser
                  buttonLabel={youtubeButtonLabel}
                  buttonLink={jobData.applyUrl}
                  videoData={videoData}
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Root>
      <Contacts
        contactDetails={jobData.recruiters}
        externalRecruiterJobTitle={jobData.externalRecruiterJobTitle}
        subtitle={contacts.subtitle}
        title={contacts.title}
      />
      {!hideBenefits && (
        <Benefits
          butterBenefits={butterBenefits}
          companyId={jobData.company.id}
        />
      )}
    </>
  );
}
