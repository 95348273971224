import { Filter, JobFilterValues } from 'components/jobs-filter/types';
import { useRouter } from 'next/router';

export function useRouterQueryFilters() {
  const router = useRouter();

  const getQueryParam = (param: string): string => {
    return router.query[param] as string;
  };

  const getQueryParamAsArray = (param: string): string[] => {
    const paramValue = getQueryParam(param);
    return paramValue ? paramValue.split(', ') : [];
  };

  const sendQueryParams = (fields: Filter[], data: JobFilterValues) => {
    const updatedQuery = { ...router.query };

    fields.forEach(({ key, formatter }) => {
      if (data[key]) {
        updatedQuery[key] = formatter(data[key]);
      }
    });

    router.push({ pathname: router.pathname, query: updatedQuery }, undefined, {
      scroll: false,
    });
  };

  const handleOnClearAllFilters = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {},
      },
      undefined,
      {
        scroll: false,
      },
    );
  };

  const handleRemoveQuery = (key: string) => {
    const query = { ...router.query };
    if (query[key]) {
      delete query[key];
    }
    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      {
        scroll: false,
      },
    );
  };

  const handleOnClearFilter = (key: string, valueToRemove: string) => {
    const query = { ...router.query };

    if (key === 'search') {
      delete query[key];
    }
    if (query[key]) {
      const valuesArray = String(query[key]).split(', ');
      const updatedValuesArray = valuesArray.filter(
        (value) => value !== valueToRemove,
      );
      query[key] = updatedValuesArray.join(', ');
      if (updatedValuesArray.length === 0) {
        delete query[key];
      }
    }

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      {
        scroll: false,
      },
    );
  };

  const setParams = (data: JobFilterValues) => {
    const keys: Array<keyof JobFilterValues> = [
      'locations',
      'companies',
      'categories',
      'functions',
      'search',
      'page',
    ];

    const fields: Filter[] = keys.map((key) => {
      return {
        formatter: (value: string | string[]) => {
          if (Array.isArray(value)) {
            return value.join(', ');
          } else {
            return value;
          }
        },
        key,
      };
    });

    console.log(fields, data);

    sendQueryParams(fields, data);
  };

  return {
    getQueryParam,
    getQueryParamAsArray,
    handleOnClearAllFilters,
    handleOnClearFilter,
    handleRemoveQuery,
    sendQueryParams,
    setParams,
  };
}
