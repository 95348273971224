import { generateUtilityClasses, Grid, styled } from '@mui/material';

export const classes = generateUtilityClasses('ContactCard', [
  'butterImage',
  'cardText',
  'contactEmail',
  'contactImageBox',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,

  display: 'block',
  height: '100%',
  overflow: 'hidden',
  padding: theme.spacing(2, 2),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    alignContent: 'flex-start',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  [`& .${classes.butterImage}`]: {
    objectFit: 'cover',
  },
  [`& .${classes.cardText}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.contactImageBox}`]: {
    borderRadius: '50%',
    height: 55,
    margin: `0 auto ${theme.spacing(2)} auto`,
    overflow: 'hidden',
    position: 'relative',
    width: 55,
  },
}));
