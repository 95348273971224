import { Hero } from '@grimme/components';
import { Box, styled } from '@mui/material';
import BreadcrumbsWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import { ButterImage } from '../butter-image';
import { TBreadcrumbs } from 'lib/butter-types';
import { THeroWithoutCard } from 'lib/butter-types/general/hero';

const Root = styled(ButterImage)(() => ({
  objectFit: 'contain',
}));

//TODO: Remove this once the hero in the CL has been updated to accept custom props or the small property has been added to the hero type
const StyledHero = styled(Box)(({ theme }) => ({
  section: {
    height: '80vh !important',
    maxHeight: '640px !important',
    minHeight: '0px !important',
  },
}));

export type BasicHeroProps = {
  breadcrumbs: TBreadcrumbs;
  hero: THeroWithoutCard;
};

export default function BasicHero({
  breadcrumbs,
  hero: { background_image, subtitle, title },
}: BasicHeroProps) {
  const image = (
    <Root
      priority
      showPlaceholder
      alt=""
      layout="fill"
      quality={100}
      src={background_image}
    />
  );

  return (
    <StyledHero>
      <Hero
        background={image}
        breadcrumbs={<BreadcrumbsWrapper {...breadcrumbs} />}
        subtitle={subtitle}
        title={title}
      />
    </StyledHero>
  );
}
