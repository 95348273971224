import useSWRImmutable from 'swr/immutable';
import { JobFilterKeys } from 'components/jobs-filter/types';
import { environment } from 'environment';
import { defaultLocale } from '../constants';
import { SWRResult } from '../types';
import { JobListResponse } from '../types/jobs';

export function useJobList(
  locale: string,
  getQueryParam: (param: string) => string,
): SWRResult<JobListResponse> {
  const translatedLocale = locale === 'de' ? 'de_DE' : defaultLocale;

  const formatQueryParam = (param: string) => {
    const values = getQueryParam(param)?.split(', ');
    return values?.map((value) => decodeURIComponent(value));
  };

  const { data, error, mutate, isValidating } =
    useSWRImmutable<JobListResponse>([
      environment.jobListApiUrl,
      {
        params: {
          categories: formatQueryParam(JobFilterKeys.CATEGORIES),
          companies: formatQueryParam(JobFilterKeys.COMPANIES),
          functions: formatQueryParam(JobFilterKeys.FUNCTIONS),
          locale: translatedLocale,
          locations: formatQueryParam(JobFilterKeys.LOCATIONS),
          page: getQueryParam(JobFilterKeys.PAGE),
          search: getQueryParam(JobFilterKeys.SEARCH),
        },
        paramsSerializer: { indexes: null },
      },
    ]);

  return {
    data: data,
    isLoading: !error && !data,
    isValidating,
    error: error,
    mutate,
  };
}
