import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@grimme/components';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { StyledEmptyMessageBox, classes } from './styles';

type EmptyMessageProps = {
  onClearAllFilters: () => void;
};

export function EmptyMessage(props: EmptyMessageProps) {
  const { onClearAllFilters } = props;
  const { t } = useTranslation();
  const title = t(
    'myGRIMME_group_emptymessage_title',
    'Keine Ergebnisse gefunden',
  );
  const subtitle = t(
    'myGRIMME_group_emptymessage_subtitle',
    ' Passe Deine Filter an oder setze alle Filter zurück, um passende Maschinen zu finden.',
  );
  const clearFilters = t(
    'myGRIMME_group_emptymessage_clearfilters',
    'Filter zurücksetzen',
  );

  return (
    <StyledEmptyMessageBox>
      <Typography className={classes.iconWrapper}>
        <FontAwesomeIcon icon={faSearch} size="6x" />
      </Typography>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
      <Button onClick={onClearAllFilters} variant="secondary">
        {clearFilters}
      </Button>
    </StyledEmptyMessageBox>
  );
}
