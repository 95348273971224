import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Hero } from '@grimme/components';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import { GrimmeGroupHome, TBreadcrumbs } from 'lib/butter-types';
import Link from 'next/link';
import BreadcrumbsWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import { ButterImage } from '../butter-image';
import { ButterImageRoot, classes, GridRoot, Root } from './styles';

export type HomePageHeroProps = {
  breadcrumbs: TBreadcrumbs;
  hero: GrimmeGroupHome['hero'];
};

export default function HomePageHero({
  breadcrumbs,
  hero: {
    background_image,
    cta_button_href,
    cta_button_label,
    cta_subtitle,
    cta_title,
    logos,
    subtitle,
    title,
  },
}: HomePageHeroProps) {
  const image = (
    <ButterImageRoot
      priority
      showPlaceholder
      alt=""
      layout="fill"
      quality={100}
      src={background_image}
    />
  );

  const ctaCard = (
    <Root>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.heroTypography} variant="h3">
          {cta_title}
        </Typography>
        <Typography className={classes.heroTypography} variant="body1">
          {cta_subtitle}
        </Typography>
        <Link href={cta_button_href}>
          <Button
            fullWidth
            className={classes.heroButton}
            endIcon={<FontAwesomeIcon icon={faAngleRight} />}
            variant="primary"
          >
            <Box className={classes.heroButtonLabel}>{cta_button_label}</Box>
          </Button>
        </Link>
      </CardContent>
    </Root>
  );

  const bottomBar = (
    <GridRoot>
      <Stack className={classes.stack} direction="row" spacing={8}>
        {logos.map((logo, index) => (
          <Link
            aria-label={logo.logo_alt}
            className={classes.logoLink}
            href={logo.logo_link}
            key={index}
          >
            <ButterImage
              showPlaceholder
              alt={logo.logo_alt}
              layout="fill"
              objectFit="contain"
              sizes="100px"
              src={logo.logo}
            />
          </Link>
        ))}
      </Stack>
    </GridRoot>
  );

  return (
    <Hero
      background={image}
      bottomBar={bottomBar}
      breadcrumbs={<BreadcrumbsWrapper {...breadcrumbs} />}
      cta={ctaCard}
      subtitle={subtitle}
      title={title}
    />
  );
}
