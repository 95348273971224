// TODO: Remove unused constants. If only used once remove aswell and use value directly.
/** Fallback locale. Use import('next/router').defaultLocale` if available */
export const DEFAULT_LOCALE = 'de';

/** Key used for saving the locale in localStorage/cookies */
export const LOCALE_COOKIE = 'NEXT_LOCALE';

/** Fallback geolocation */
export const DEFAULT_GEOLOCATION = {
  countryCode: 'DE',
  countryFlag: '/assets/img/flag_de.svg',
  countryName: 'Deutschland',
  latitude: '50.93790',
  longitude: '6.87910',
  zipcode: '49401',
};
