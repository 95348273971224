import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@grimme/components';
import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useDebounce } from 'hooks/utils';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { JobFilterKeys } from '../types';
import { StyledBox, classes } from './styles';

export const Search = () => {
  const { getQueryParam, handleOnClearFilter, setParams } =
    useRouterQueryFilters();
  const searchValue = getQueryParam(JobFilterKeys.SEARCH);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | undefined>(searchValue);
  const debouncedValue = useDebounce(search);
  const title = t('myGRIMME_group_search', 'Suchen');

  const hasValue = !!search;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {
    if (searchValue != debouncedValue) {
      setParams({ search: search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onAdornmentClick = () => {
    handleOnClearFilter(JobFilterKeys.SEARCH, search);
  };

  const endAdornment = (
    <InputAdornment
      className={classNames(classes.adornment, { [classes.pointer]: hasValue })}
      disablePointerEvents={!hasValue}
      onClick={onAdornmentClick}
      position="end"
      role="button"
    >
      <FontAwesomeIcon icon={hasValue ? faXmark : faMagnifyingGlass} />
    </InputAdornment>
  );

  return (
    <StyledBox>
      <TextField
        fullWidth
        className={classes.textField}
        defaultValue={search || ''}
        InputProps={{
          endAdornment: endAdornment,
        }}
        label={title}
        onChange={handleInputChange}
        value={search || ''}
      />
    </StyledBox>
  );
};
