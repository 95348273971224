import { LocationId, LocationName } from 'components/jobs-filter/types';
import { JobResources } from 'hooks/types/jobs';

export const translateLocationId = (inputId: string) => {
  switch (inputId) {
    case LocationId.DAM:
    case LocationId.RIESF:
      return LocationName.GRIMME;
    case LocationId.INO:
      return LocationName.INTERNORM;
    case LocationId.RIE:
      return LocationName.RICON;
    default:
      return '';
  }
};

type Result =
  | { key: string; values: { label: string; value: string }[] }
  | undefined;

export const translateIdsToLabels = (
  inputIds: { key: string; value?: string[] }[],
  data: JobResources,
): Result[] => {
  const results: Result[] = [];
  inputIds.forEach((inputId) => {
    const array = data[inputId.key];
    if (array && inputId.value) {
      const values: { label: string; value: string }[] = [];
      inputId.value.forEach((id) => {
        const foundItem = array.find((item) => item.id === id);
        if (foundItem) {
          let label = foundItem.name;
          if (inputId.key === 'locations') {
            label =
              (foundItem.zipCode || '') +
              ' ' +
              foundItem.city +
              translateLocationId(foundItem.id);
          }
          values.push({
            label: label,
            value: id,
          });
        }
      });
      if (values.length > 0) {
        results.push({
          key: inputId.key,
          values: values,
        });
      }
    }
  });
  return results;
};
