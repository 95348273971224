import {
  faAngleRight,
  faShareFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@grimme/components';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import Image from 'next/legacy/image';
import { Root, classes } from './styles';

type YoutubeTeaser = {
  buttonLabel: string;
  buttonLink: string;
  videoData: {
    thumbnailUrl: string;
    title: string;
    youtubeUrl: string;
  };
};

export function YoutubeTeaser(props: YoutubeTeaser) {
  const { buttonLabel, buttonLink, videoData } = props;
  return (
    <Root>
      <Button
        className={classes.videoButton}
        endIcon={<FontAwesomeIcon icon={faAngleRight} />}
        href={buttonLink}
        variant="primary"
      >
        {buttonLabel}
      </Button>
      <Grid
        item
        className={classes.gridInner}
        component={Link}
        href={videoData.youtubeUrl}
        lg={3}
        md={4}
        target="_blank"
        xs={4}
      >
        <Box className={classes.imageBox}>
          <Image
            unoptimized
            alt=""
            className={classes.image}
            layout="fill"
            src={videoData.thumbnailUrl}
          />
          <Stack className={classes.videoOverlay}>
            <FontAwesomeIcon icon={faShareFromSquare} />
          </Stack>
        </Box>
        <Typography
          className={classes.videoTitle}
          component="h2"
          variant="body1"
        >
          {videoData.title}
        </Typography>
      </Grid>
    </Root>
  );
}
