import { Card, generateUtilityClasses, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButterImage } from '../butter-image';

export const classes = generateUtilityClasses('HomePageHero', [
  'button',
  'butterImage',
  'cardContent',
  'heroButton',
  'heroButtonLabel',
  'heroTypography',
  'logoLink',
  'stack',
]);

export const Root = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,

  display: 'block',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.heroButton}`]: {
    textAlign: 'start',
  },
  [`& .${classes.heroButtonLabel}`]: {
    width: '100%',
  },
  [`& .${classes.heroTypography}`]: {
    marginBottom: theme.spacing(2),
  },
}));

export const ButterImageRoot = styled(ButterImage)(() => ({
  objectFit: 'cover',
}));

export const GridRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.ourShadows.card,
  height: '100%',
  width: '100%',
  [`& .${classes.butterImage}`]: {
    objectFit: 'contain',
  },
  [`& .${classes.logoLink}`]: {
    height: 40,
    minWidth: 100,
    position: 'relative',
  },
  [`& .${classes.stack}`]: {
    justifyContent: 'space-between',
    overflow: 'auto',
    padding: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
}));
