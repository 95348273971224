import theme from '@grimme/theme';
import { Box } from '@mui/material';
import { ButterPage, GlobalSettings } from 'lib';
import Footer from './footer';
import Navbar from './navbar/navbar';

export type LayoutProps = {
  children: React.ReactNode;
  globalSettings: ButterPage<GlobalSettings>;
};

export function Layout({ children, globalSettings }: LayoutProps) {
  const socialLinks = globalSettings.fields.social_links;
  const topLinks = globalSettings.fields.top_links;

  return (
    <>
      <Navbar
        greeting={globalSettings.fields.greeting}
        loggedInMenu={globalSettings.fields.logged_in_menu}
        loggedOutMenu={globalSettings.fields.logged_out_menu}
        navbarData={globalSettings.fields.navbar}
        navigationLinks={globalSettings.fields.navigation_menu}
        socialLinks={socialLinks}
        topLinks={topLinks}
      />
      <Box bgcolor={theme.palette.grey[100]}>{children}</Box>
      <Footer
        footerData={globalSettings.fields.footer}
        navigationLinks={globalSettings.fields.navigation_menu}
        socialLinks={socialLinks}
        topLinks={topLinks}
      />
    </>
  );
}
