import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('SearchField', [
  'adornment',
  'pointer',
  'textField',
]);

export const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.adornment}`]: {
    color: 'inherit',
  },

  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.textField}`]: {
    color: 'inherit',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxWidth: '100%',
    },
  },
}));
