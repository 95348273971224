import { Grid, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('SectionTitle', [
  'box',
  'subtitle',
  'title',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.box}`]: {
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.subtitle}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    marginTop: theme.spacing(3),
  },
}));
