import { Stack, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('JobListLayout', [
  'caption',
  'card',
  'colorGrey',
  'icon',
]);

export const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.card}`]: {
    '&:hover': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-1),
      transition: '0.3s ease-in-out',
    },
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.ourShadows.card,
    cursor: 'pointer',
    padding: theme.spacing(2),
    transition: '0.3s ease-in-out',
  },
  [`& .${classes.caption}`]: {
    display: 'unset',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  [`& .${classes.colorGrey}`]: {
    color: theme.palette.grey[400],
  },
  [`& .${classes.icon}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.primary.main,
      display: 'block',
      height: theme.spacing(3),
      margin: `auto ${theme.spacing(3)} auto auto`,
      width: theme.spacing(3),
    },
  },
}));
