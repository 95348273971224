import theme from '@grimme/theme';
import { Stack, Typography } from '@mui/material';

type JobInfoStackProps = {
  color?: string;
  data: string | string[];
  label: string;
};

export const JobInfoStack = (props: JobInfoStackProps) => (
  <Stack direction={'row'} spacing={0.5}>
    <Typography color={theme.palette.grey[400]} variant="body2">
      {props.label + ':'}
    </Typography>
    <Typography variant="body2">{props.data}</Typography>
  </Stack>
);
