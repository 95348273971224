import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { ButterImage } from 'components/butter-image';
import Link from 'next/link';
import { StyledGrid, classes } from './styles';

type ContactCardProps = {
  contactDetails: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    mobilePhone: string;
    phone: string;
    title: string;
  };
  externalRecruiterJobTitle: string;
  image: string;
};
//TODO: Fetch contact image from api and inser it, also use mobile geschäftlich instead of phone and field custom jobtitle instead of title
export function ContactCard(props: ContactCardProps) {
  const { contactDetails, externalRecruiterJobTitle, image } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const text = isMobile ? 'body2' : 'body1';
  const nameText = isMobile ? 'h6' : 'h5';

  return (
    <StyledGrid container item>
      <Box className={classes.contactImageBox}>
        <ButterImage
          showPlaceholder
          alt=""
          className={classes.butterImage}
          layout="fill"
          sizes="55px"
          src={image}
        />
      </Box>
      <Stack className={classes.cardText}>
        <Typography component="p" variant={nameText}>
          {contactDetails.firstName} {contactDetails.lastName}
        </Typography>
        <p className="g-typography-body2 text-grey-400 my-1">
          {externalRecruiterJobTitle}
        </p>
        {contactDetails.phone && (
          <Link
            passHref
            className="flex flex-row gap-2 items-center hover:text-primary"
            href={`tel:${contactDetails.phone}`}
          >
            <FontAwesomeIcon className="h-4 w-4" icon={faPhone} />
            <p className="g-typography-body1">{contactDetails.phone}</p>
          </Link>
        )}
        {contactDetails.mobilePhone && (
          <Link
            passHref
            className="flex flex-row gap-2 items-center hover:text-primary"
            href={`tel:${contactDetails.mobilePhone}`}
          >
            <FontAwesomeIcon className="h-4 w-4" icon={faWhatsapp} />
            <p className="g-typography-body1">{contactDetails.mobilePhone}</p>
          </Link>
        )}
      </Stack>
    </StyledGrid>
  );
}
