import useSWRImmutable from 'swr/immutable';
import { environment } from 'environment';
import { defaultLocale } from '../constants';
import { SWRResult } from '../types';
import { JobResources } from '../types/jobs';

export function useJobResources(locale: string): SWRResult<JobResources> {
  const translatedLocale = locale === 'de' ? 'de_DE' : defaultLocale;
  const { data, error, mutate, isValidating } = useSWRImmutable<JobResources>([
    environment.jobResourcesApiUrl,
    {
      params: {
        locale: translatedLocale,
      },
    },
  ]);

  return {
    data: data,
    isLoading: !error && !data,
    isValidating,
    error: error,
    mutate,
  };
}
