import { SpacedSection } from '@grimme/components';
import { Container, Grid } from '@mui/material';
import { SectionTitle } from '../section-title';
import { ContactCard } from './contact-card';
import { Root } from './styles';

export type ContactProps = {
  contactDetails: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    mobilePhone: string;
    phone: string;
    title: string;
  }[];
  externalRecruiterJobTitle: string;
  subtitle: string;
  title: string;
};

export const Contacts = (props: ContactProps) => {
  const { contactDetails, externalRecruiterJobTitle, subtitle, title } = props;

  const fallbackContactImage = 'https://cdn.buttercms.com/LlU6ZL4MSdeF6eEffmAE';
  return (
    <Root>
      <SpacedSection px="large">
        <Container>
          <SectionTitle fullWidth subtitle={subtitle} title={title} />
          <Grid container spacing={{ md: 3, xs: 1 }}>
            {contactDetails &&
              contactDetails.map((contact, index) => (
                <Grid item key={index} md={6} xs={12}>
                  <ContactCard
                    contactDetails={contact}
                    externalRecruiterJobTitle={externalRecruiterJobTitle}
                    image={fallbackContactImage}
                    key={index}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      </SpacedSection>
    </Root>
  );
};
