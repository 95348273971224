import { useSession } from '@grimme/next-grimme-auth';
import useSWRImmutable from 'swr/immutable';
import { environment } from '~/environment';
import { SWRResult } from '../types';
import { IUser } from './types';

export function useProfile(): SWRResult<IUser> {
  const { data: sessionData, status } = useSession();

  // Only load the data once and don't revalidate it
  const { data, error, isValidating, mutate } = useSWRImmutable<IUser>(
    () => {
      if (status != 'authenticated') {
        return undefined;
      }

      return [
        `${environment.gridApi}/api/v1/user/me`,
        {
          headers: {
            Authorization: `Bearer ${sessionData?.accessToken}`,
          },
        },
      ];
    },
    {
      shouldRetryOnError: false,
    },
  );

  return {
    data: data,
    error: error,
    isLoading: !error && !data,
    isValidating: isValidating,
    mutate,
  };
}
