import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { Box, Stack, Typography } from '@mui/material';
import { Root, classes } from './styles';

type BenefitItemProps = {
  iconColor: string;
  item: {
    companies: string;
    icon: string;
    icon_type: string;
    subtitle: string;
    title: string;
  };
};

export function BenefitItem(props: BenefitItemProps) {
  const { iconColor, item } = props;
  const color =
    iconColor === 'black'
      ? theme.palette.common.black
      : theme.palette.primary.main;
  const border =
    iconColor === 'black'
      ? `2px solid ${theme.palette.primary.main}`
      : `2px solid ${theme.palette.common.black}`;
  return (
    <Root item md={4} sm={2} xs={4}>
      <Stack className={classes.stackWrapper} spacing={2}>
        <Box border={border} className={classes.iconBox} color={color}>
          <FontAwesomeIcon
            className={classes.fontAwesomeIcon}
            icon={
              {
                iconName: item.icon,
                prefix: item.icon_type,
              } as IconLookup
            }
          />
        </Box>
        <Stack className={classes.stackInner}>
          <Typography component="p" variant="h4">
            {item.title}
          </Typography>
          {item.subtitle && (
            <Typography
              className={classes.typography}
              component="p"
              variant="body1"
            >
              {item.subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Root>
  );
}
