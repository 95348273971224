import {
  IconLookup,
  IconName,
  IconPrefix,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { Typography } from '@mui/material';
import { GlobalSettings } from 'lib';
import { StyledImagePlaceholder, classes } from './styles';

export const createImagePlaceHolder = (shortName) => {
  return (
    <StyledImagePlaceholder>
      <Typography className={classes.imageName} variant="body1">
        {shortName}
      </Typography>
    </StyledImagePlaceholder>
  );
};

export const mapLoggedOutMenuItems = (
  menu: GlobalSettings['logged_out_menu'],
) => {
  return menu.menu_items.map((item) => {
    return {
      icon: (
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon={[item.icon_type as IconPrefix, item.icon as IconName]}
        />
      ),
      text: item.text,
    };
  });
};

export const mapLoggedInMenuItems = (
  menu: GlobalSettings['logged_in_menu'],
) => {
  return menu.menu_items.map((item) => {
    return {
      icon: (
        <FontAwesomeIcon
          icon={
            {
              iconName: item.icon as IconName,
              prefix: item.icon_type,
            } as IconLookup
          }
        />
      ),
      link: item.link,
      text: item.text,
    };
  });
};
