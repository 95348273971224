import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  generateUtilityClasses,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { useFormattedProfileData } from '~/hooks';

export const classes = generateUtilityClasses('NavbarWithContact', [
  'greeting',
  'iconLoggedInIndicator',
]);

export const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
  svg: {
    color: theme.palette.common.white,
  },
  [`& .${classes.greeting}`]: {
    display: 'grid',
    marginRight: theme.spacing(1),
    placeContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
  [`& .${classes.iconLoggedInIndicator}`]: {
    backgroundColor: theme.palette.success.main,
    borderRadius: '90%',
    bottom: theme.spacing(0.5),
    height: 9,
    position: 'absolute',
    right: theme.spacing(0.5),
    width: 9,
  },
}));
type UserGreetingProps = {
  greeting: string;
  setIsShowingUserSettings: (open: boolean) => void;
};

export const UserGreeting = forwardRef(
  (props: UserGreetingProps, userIconRef: ForwardedRef<HTMLButtonElement>) => {
    const { greeting, setIsShowingUserSettings } = props;

    const { isUserDataLoaded, userGreeting } =
      useFormattedProfileData(greeting);
    return (
      <StyledStack
        direction="row"
        onClick={() => setIsShowingUserSettings(true)}
      >
        {isUserDataLoaded && (
          <Typography className={classes.greeting} variant="body1">
            {userGreeting}
          </Typography>
        )}
        <IconButton color="inherit" ref={userIconRef}>
          {isUserDataLoaded && (
            <Box className={classes.iconLoggedInIndicator} />
          )}
          <FontAwesomeIcon icon={faUser} />
        </IconButton>
      </StyledStack>
    );
  },
);

UserGreeting.displayName = 'UserGreeting';
