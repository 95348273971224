export type Filter = {
  formatter: (value: string | number | string[]) => string | string[];
  key: keyof JobFilterValues;
};

export type JobFilterValues = {
  categories?: string | string[];
  companies?: string | string[];
  functions?: string | string[];
  locations?: string | string[];
  page?: number;
  search?: string | string[];
};

//Enum to represent the different filter options for the functions. These will be used eventually to query for different images.
export enum Functions {
  ADMINISTRATION = 'cust_Rec_function_Administration',
  IT = 'cust_Rec_function_IT',
  LOGISTICS = 'cust_Rec_function_Logistik',
  OTHER = 'cust_Rec_function_Sonstige',
  PRODUCTION = 'cust_Rec_function_production',
  SALES = 'cust_Rec_function_Sales',
  SERVICE = 'cust_Rec_function_Service',
  TECHNICAL_DEVELOPMENT = 'cust_Rec_function_Technical Development',
}

export enum JobFilterKeys {
  CATEGORIES = 'categories',
  COMPANIES = 'companies',
  FUNCTIONS = 'functions',
  LOCATIONS = 'locations',
  PAGE = 'page',
  SEARCH = 'search',
}

export enum LocationId {
  DAM = 'DAM',
  INO = 'INO',
  RIE = 'RIE',
  RIESF = 'RIESF',
}

export enum LocationName {
  GRIMME = ' - GRIMME',
  INTERNORM = ' - INTERNORM',
  RICON = ' - RICON',
}
