import { styled } from '@mui/material';
import Image, { ImageLoader, ImageProps } from 'next/legacy/image';

const StyledImage = styled(Image)(() => ({
  backgroundPosition: 'center !important',
  backgroundRepeat: 'no-repeat',
}));

const imageCacheDuration = 60 * 60 * 24 * 30 * 6; // 6 months

export const butterImageLoader: ImageLoader = ({ quality, src, width }) => {
  const butterSrc = src.split('/').pop();

  return `https://cdn.buttercms.com/cache=expiry:${imageCacheDuration}/resize=width:${width}/output=format:webp,quality:${
    quality || 75
  }/${butterSrc}`;
};

// Generates a placeholder image by requesting a very low quality image from ButterCMS.
export const placeholderImageUrl = ({
  placeholderWidth = 20,
  src,
}: {
  placeholderWidth?: number;
  src: string;
}) => {
  const butterSrc = src.split('/').pop();

  return `https://cdn.buttercms.com/cache=expiry:${imageCacheDuration}/resize=width:${placeholderWidth}/output=format:webp,quality:1/${butterSrc}`;
};

// TODO: Fix passing of showPlaceholder, placeholderWidth to Image component
export function ButterImage(
  props: Omit<ImageProps, 'loader' | 'placeholder' | 'blurDataURL'> & {
    placeholderWidth?: number;
    showPlaceholder?: boolean;
  },
) {
  return (
    <StyledImage
      alt={props.alt}
      {...props}
      blurDataURL={
        (props.showPlaceholder ?? true)
          ? placeholderImageUrl({
              placeholderWidth: props.placeholderWidth,
              src: props.src as string,
            })
          : undefined
      }
      loader={butterImageLoader}
      placeholder={(props.showPlaceholder ?? false) ? 'blur' : 'empty'}
    />
  );
}
