import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@grimme/components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { StyledDiv } from './styles';
import { translateIdsToLabels } from './utils';
import { JobFilterKeys } from 'components/jobs-filter/types';
import { useJobResources } from 'hooks/useJobResources/useJobResources';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';

type FilterChipsProps = {
  justify?: 'start' | 'end';
  modifier?: 'light' | 'dark';
  onClearAllFilters: () => void;
  onClearFilter: (key: string, value: string) => void;
};

export function FilterChips(props: FilterChipsProps) {
  const { onClearAllFilters, onClearFilter } = props;
  const router = useRouter();
  const { getQueryParam, getQueryParamAsArray } = useRouterQueryFilters();
  const { data: resourceData } = useJobResources(router.locale);

  const { t } = useTranslation();
  const description = t(
    'myGRIMME_group_emptymessage_clearfilters',
    'Filter zurücksetzen',
  );

  const filterChipList = Object.values(JobFilterKeys)
    .filter((key) => key !== JobFilterKeys.SEARCH)
    .map((key) => {
      const values = getQueryParamAsArray(key);
      return {
        key,
        value: values.length > 0 ? values : undefined,
      };
    });

  const filterList =
    resourceData && translateIdsToLabels(filterChipList, resourceData);

  const hasMultipleValues = filterList?.some(
    (filter) => filter.values.length > 1,
  );

  if (getQueryParam(JobFilterKeys.SEARCH)) {
    filterList.push({
      key: JobFilterKeys.SEARCH,
      values: [
        {
          label: `"${getQueryParam(JobFilterKeys.SEARCH)}"`,
          value: `"${getQueryParam(JobFilterKeys.SEARCH)}"`,
        },
      ],
    });
  }

  const handleClearAllFilters = () => {
    onClearAllFilters();
  };

  return (
    <StyledDiv>
      {resourceData &&
        filterList.map((filter) => {
          if (filter?.values?.length > 0 === true) {
            return filter.values.map((value, index) => (
              <Chip
                selected
                key={`${filter.key}-${index}`}
                label={value.label}
                onDelete={() => {
                  onClearFilter(filter.key, value.value);
                }}
              />
            ));
          } else {
            return null;
          }
        })}
      {resourceData && (filterList.length > 1 || hasMultipleValues) && (
        <Button
          onClick={handleClearAllFilters}
          size="small"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
          variant="text"
        >
          {description}
        </Button>
      )}
    </StyledDiv>
  );
}
