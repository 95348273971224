import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('BrowseEmptyMessage', [
  'iconWrapper',
  'subtitle',
  'title',
]);

export const StyledEmptyMessageBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8, 4),
  textAlign: 'center',
  [`.${classes.iconWrapper}`]: {
    color: theme.palette.grey[200],
    marginBottom: theme.spacing(3),
  },
  [`.${classes.subtitle}`]: {
    marginBottom: theme.spacing(3),
    maxWidth: 400,
  },
  [`.${classes.title}`]: {
    marginBottom: theme.spacing(2),
  },
}));
