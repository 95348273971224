import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Breadcrumbs } from '@grimme/components';
import { environment } from 'environment';
import { TBreadcrumbs } from 'lib/butter-types';
import Link from 'next/link';

export default function BreadcrumbsWrapper({ items }: TBreadcrumbs) {
  return (
    <Breadcrumbs variant="dark">
      <Breadcrumb
        onPointerEnterCapture
        onPointerLeaveCapture
        placeholder
        href={environment.grimmeWebsiteUrl}
      >
        <FontAwesomeIcon icon={faHouse} />
      </Breadcrumb>
      {items.map(({ label, url }, index) => {
        const breadcrumb = (
          <Breadcrumb
            onPointerEnterCapture
            onPointerLeaveCapture
            placeholder
            active={index === items.length - 1}
            key={index}
          >
            {label}
          </Breadcrumb>
        );

        if (index === items.length - 1) {
          return breadcrumb;
        }

        return (
          <Link
            legacyBehavior
            passHref
            href={url && url !== '' ? url : ''}
            key={index}
          >
            {breadcrumb}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
