import { Box, Stack, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('JobLayout', [
  'icon',
  'titleDivider',
]);

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [`& .${classes.titleDivider}`]: {
    marginBottom: theme.spacing(4),
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    height: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.75),
    width: theme.spacing(1.5),
  },
}));
