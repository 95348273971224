import { ContactAssistant } from '@grimme/components';
import { Box, generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('NavbarWithContact', [
  'greeting',
  'icon',
  'iconLoggedInIndicator',
  'iconButton',
  'imageName',
  'imagePlaceholder',
  'logo',
  'userIconStack',
]);

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.greeting}`]: {
    display: 'grid',
    marginRight: theme.spacing(1),
    placeContent: 'center',
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.common.white,
    height: 24,
    width: 24,
  },
  [`& .${classes.iconLoggedInIndicator}`]: {
    backgroundColor: theme.palette.success.main,
    borderRadius: '90%',
    bottom: theme.spacing(0.5),
    height: 9,
    position: 'absolute',
    right: theme.spacing(0.5),
    width: 9,
  },
  [`& .${classes.iconButton}`]: {
    padding: theme.spacing(1.5),
  },

  [`& .${classes.logo}`]: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
    minHeight: 'auto',
    width: 120,
  },
  [`& .${classes.userIconStack}`]: {
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
}));

export const StyledContactAssistant = styled(ContactAssistant)(({ theme }) => ({
  '& .GC-ContactAssistant-links': {
    right: '-14px',
  },
  '&.GC-ContactAssistant-root': {
    position: 'unset',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(1),
  transition: 'all 0.2s ease-in-out',
  zIndex: 9,
}));

export const StyledImagePlaceholder = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '90%',
  color: theme.palette.common.white,
  height: 48,
  width: 48,
  [`& .${classes.imageName}`]: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
    width: '100%',
  },
}));
