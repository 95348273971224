import { Box, alpha, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('YoutubeTeaser', [
  'gridInner',
  'image',
  'imageBox',
  'videoButton',
  'videoOverlay',
  'videoTitle',
]);

export const Root = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(10),
  [`& .${classes.gridInner}`]: {
    '&:hover': {
      color: `${theme.palette.primary.main}`,
      [`& .${classes.videoOverlay}`]: {
        opacity: 1,
      },
    },
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
  },
  [`& .${classes.imageBox}`]: {
    borderRadius: theme.shape.borderRadius,

    flexGrow: theme.spacing(1),
    minHeight: 165,
    overflow: 'hidden',
    position: 'relative',
  },
  [`& .${classes.image}`]: {
    objectFit: 'cover',
  },
  [`& .${classes.videoButton}`]: {
    marginBottom: theme.spacing(5),
  },
  [`& .${classes.videoTitle}`]: {
    display: '-webkit-box',
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all 0.3s ease-in-out',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    wordWrap: 'break-word',
  },
  [`& .${classes.videoOverlay}`]: {
    alignItems: 'center',
    backgroundColor: `${alpha(theme.palette.common.black, 0.5)}`,
    color: theme.palette.common.white,
    fontSize: '30px',
    inset: '0',
    justifyContent: 'center',
    opacity: 0,
    position: 'absolute',
    transition: 'all 0.3s ease-in-out',
  },
}));
