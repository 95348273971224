import { TitleDivider } from '@grimme/components';
import { Box, Grid, Typography } from '@mui/material';
import { StyledGrid, classes } from './styles';

type SectionTitle = {
  fullWidth?: boolean;
  overline?: string;
  showDivider?: boolean;
  subtitle?: string;
  title?: string;
};

export function SectionTitle({
  fullWidth = false,
  overline,
  showDivider = true,
  subtitle,
  title,
}: SectionTitle) {
  return (
    <StyledGrid container>
      <Grid item md={fullWidth ? 12 : 8} xs={12}>
        {overline && (
          <Typography component="p" variant="h6">
            {overline}
          </Typography>
        )}
        <Typography className={classes.title} component="h1" variant="h2">
          {title}
        </Typography>

        <Typography
          className={classes.subtitle}
          component="p"
          variant="subtitle1"
        >
          {subtitle}
        </Typography>
        {showDivider && (
          <Box className={classes.box}>
            <TitleDivider variant="left" />
          </Box>
        )}
      </Grid>
    </StyledGrid>
  );
}
