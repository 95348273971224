import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useJobList } from 'hooks/useJobList/useJobList';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { JobFilterKeys } from '../jobs-filter/types';
import { FilterChips } from './chips';
import { EmptyMessage } from './empty';
import { JobListLayout } from './job-list-layout/job-list-layout';
import { PaginationBar } from './pagination-bar';
import { StyledContainer, classes } from './styles';
type JobsListProps = {
  onPageChange: () => void;
};
export function JobsList(props: JobsListProps) {
  const { onPageChange } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const title = t('myGRIMME_group_jobs_found', 'JOBS GEFUNDEN');

  const {
    getQueryParam,
    handleOnClearAllFilters,
    handleOnClearFilter,
    setParams,
  } = useRouterQueryFilters();
  const { data: jobListData, isValidating: jobListIsValidating } = useJobList(
    router.locale,
    getQueryParam,
  );
  const totalPagesWithTenItemsPerPage =
    jobListData && Math.ceil(jobListData.totalCount / 10);
  const loadingDataSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const totalCountTitle = jobListData && jobListData.totalCount + ' ' + title;
  const totalCountLoading =
    jobListIsValidating && t('myGRIMME_group_loading', 'Loading ...');

  const handlePageChange = (page) => {
    setParams({ page: page });
    onPageChange();
  };

  return (
    <StyledContainer>
      <Box className={classes.chips}>
        <Typography className={classes.totalAmount} variant="body1">
          {totalCountTitle}
          {totalCountLoading}
        </Typography>
        <FilterChips
          justify="end"
          modifier="dark"
          onClearAllFilters={handleOnClearAllFilters}
          onClearFilter={handleOnClearFilter}
        />
      </Box>

      {jobListIsValidating && (
        <Stack mb={5} spacing={2}>
          {loadingDataSize.map((item, index) => (
            <Box className={classes.card} key={index}>
              <Stack direction={'row'}>
                <Stack spacing={1} width={'100%'}>
                  <Skeleton width={'25%'} />
                  <Skeleton width={'60%'} />
                  <Skeleton width={'40%'} />
                </Stack>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faChevronRight}
                />
              </Stack>
            </Box>
          ))}
        </Stack>
      )}

      {jobListData && <JobListLayout jobListData={jobListData} />}
      {jobListData && jobListData.totalCount === 0 && (
        <EmptyMessage onClearAllFilters={handleOnClearAllFilters} />
      )}
      {jobListData && jobListData.totalCount !== 0 && (
        <PaginationBar
          onPageChange={handlePageChange}
          page={parseInt(getQueryParam(JobFilterKeys.PAGE)) || 1}
          totalPages={totalPagesWithTenItemsPerPage}
        />
      )}
    </StyledContainer>
  );
}
