import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { Footer as GrimmeFooter } from '@grimme/components';
import { GlobalSettings } from 'lib';

export type FooterProps = {
  footerData: GlobalSettings['footer'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
};

export default function Footer({
  footerData,
  navigationLinks,
  socialLinks,
  topLinks,
}: FooterProps) {
  const mappedNavigationLinks = navigationLinks
    .map((l) => l.fields)
    .map((link) => ({
      label: link.label,
      url: link.url,
    }));

  return (
    <GrimmeFooter
      data={{
        company_information: [
          {
            city: footerData.city,
            company_name: footerData.company_name,
            email: footerData.email,
            phone: footerData.phone,
            postal_code: footerData.postal_code,
            street_address: footerData.street_address,
            website: footerData.website,
          },
        ],
        legal_information_links: footerData.legal_information_links,
        title: footerData.title,
      }}
      sitemap={[
        {
          caption: footerData.sitemap_title,
          links: mappedNavigationLinks,
        },
        {
          caption: topLinks.title,
          links: topLinks.links,
        },
      ]}
      socialLinks={socialLinks.links.map((socialLink) => ({
        icon: {
          iconName: socialLink.icon,
          prefix: socialLink.icon_type,
        } as IconLookup,
        url: socialLink.url,
      }))}
    />
  );
}
