import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('JobsFilter', ['filterGrid']);

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingBottom: theme.spacing(5),
  [`& .${classes.filterGrid}`]: {
    display: 'grid',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gap: theme.spacing(3),
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
}));
