import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select } from '@grimme/components';
import { Box, Container, Stack, Typography } from '@mui/material';
import { JobResources } from 'hooks/types/jobs';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Search } from '../jobs-filter/search';
import { JobFilterKeys } from '../jobs-filter/types';
import { StyledBox, classes } from './styles';

type CareerPageJobsFilterProps = {
  resources: JobResources;
};

export function CareerPageJobsFilter(props: CareerPageJobsFilterProps) {
  const { resources } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const { getQueryParam, setParams } = useRouterQueryFilters();

  const functionsOptions = useMemo(() => {
    if (resources.functions) {
      return resources.functions.map((functions) => ({
        label: functions.name,
        value: functions.id,
      }));
    }
    return [];
  }, [resources.functions]);

  const categoriesOptions = useMemo(() => {
    if (resources.categories) {
      return resources.categories.map((categories) => ({
        label: categories.name,
        value: categories.id,
      }));
    }
    return [];
  }, [resources.categories]);

  const functionsQuery = getQueryParam(JobFilterKeys.FUNCTIONS);
  const categoriesQuery = getQueryParam(JobFilterKeys.CATEGORIES);

  const handleNavigation = () => {
    router.push(
      { pathname: `/${router.locale}/career/jobs`, query: router.query },
      undefined,
      {
        scroll: false,
      },
    );
  };

  const title = t('myGRIMME_group_career_jobsfilter_title', 'Jobsuche');
  const functions = t('myGRIMME_group_functions', 'Funktionsbereich');
  const categories = t('myGRIMME_group_categories', 'Karrierelevel');
  const buttonText = t('myGRIMME_group_career_searchjobs', 'Jobs finden');

  return (
    <StyledBox>
      <Container>
        <Stack>
          <Typography mb={3} mt={5} variant="h6">
            {title}
          </Typography>
          <Box className={classes.filterGrid}>
            <Search />
            <Select
              fullWidth
              label={functions}
              name="functions"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setParams({ functions: e.target.value });
              }}
              options={functionsOptions}
              value={functionsQuery || ''}
            />
            <Select
              fullWidth
              label={categories}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setParams({ categories: e.target.value });
              }}
              options={categoriesOptions}
              value={categoriesQuery || ''}
            />
            <Button
              endIcon={<FontAwesomeIcon icon={faAngleRight} />}
              onClick={handleNavigation}
              variant="primary"
            >
              {buttonText}
            </Button>
          </Box>
        </Stack>
      </Container>
    </StyledBox>
  );
}
