import { Geolocation } from '@grimme/types';
import useSWRImmutable from 'swr/immutable';
import { environment } from '~/environment';
import { SWRResult } from '../types';

export function useGeolocation(locale: string): SWRResult<Geolocation> {
  // Only load the data once and don't revalidate it
  const { data, error } = useSWRImmutable<Geolocation>([
    `${environment.myGrimmeApi}/geolocation`,
    {
      params: {
        language: locale,
      },
    },
  ]);
  return {
    data: data,
    error: error,
    isLoading: !error && !data,
  };
}
